.App {
  text-align: center;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.image-container {
  position: relative;
}

.image-slider {
  display: flex;
  animation: moveSlideshow 12s linear infinite;
  white-space: nowrap;
}

.carousel-image {
  height: 118px;
  padding: 34px 50px;
}

@keyframes moveSlideshow {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}


@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

.darkBackground {
  position: relative;
  background-image: url('https://i.imgur.com/tsOxIOo.jpg'); /* Replace with the URL of your background image */
  background-size: cover;
  background-repeat: no-repeat;
}

.darkBackground::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.80); /* Change the alpha value (0.7) to adjust darkness */
}

.darkBackground > * {
  position: relative;
  z-index: 1;
}







